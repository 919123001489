import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class UserApi extends BaseApi {
  signin(username, password) {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    const localVarAxiosArgs = {
      url: `/user/signin`,
      options: {
        method: 'POST',
        data: formData,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  signAsAdmin(token) {
    const formData = new FormData();
    formData.append('token', token);

    const localVarAxiosArgs = {
      url: `/user/sign_as_admin`,
      options: {
        method: 'POST',
        data: formData,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  forgotPassword(primary_contact_email) {
    const formData = new FormData();
    formData.append('primary_contact_email', primary_contact_email);

    const localVarAxiosArgs = {
      url: `/user/forgot-password`,
      options: {
        method: 'POST',
        data: formData,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  resetPassword(token, new_password, confirm_new_password) {
    const localVarAxiosArgs = {
      url: `/user/password-reset`,
      options: {
        method: 'POST',
        data: {
          token,
          new_password,
          confirm_new_password,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  resetPasswordChargeBee(token, new_password, confirm_new_password) {
    const localVarAxiosArgs = {
      url: `/user/password-reset-cb`,
      options: {
        method: 'POST',
        data: {
          token,
          new_password,
          confirm_new_password,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updatePassword(current_password, new_password, confirm_password) {
    const localVarAxiosArgs = {
      url: `/user/update-password`,
      options: {
        method: 'POST',
        data: {
          current_password,
          new_password,
          confirm_password,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getSubaccount() {
    const localVarAxiosArgs = {
      url: `/user/subaccount`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteSubaccount(id, email) {
    const localVarAxiosArgs = {
      url: `/user/subaccount`,
      options: {
        method: 'DELETE',
        data: {
          id,
          email,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  addSubaccount(company_name, primary_contact_email, password) {
    const localVarAxiosArgs = {
      url: `/user/add_subaccount`,
      options: {
        method: 'POST',
        data: {
          company_name,
          primary_contact_email,
          password,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  signinSubaccount(username) {
    const frm = new FormData();
    frm.append('username', username);

    const localVarAxiosArgs = {
      url: `/user/signin_subaccount`,
      options: {
        method: 'POST',
        data: frm,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getExtraLogins() {
    const localVarAxiosArgs = {
      url: `/user/extra_logins`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  addExtraLogin(email, password) {
    const localVarAxiosArgs = {
      url: `/user/extra_login`,
      options: {
        method: 'POST',
        data: {
          email,
          password,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteExtraLogin(login_extra_id) {
    const localVarAxiosArgs = {
      url: `/user/extra_login`,
      options: {
        method: 'DELETE',
        params: {
          login_extra_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  me(start_time, end_time) {
    const localVarAxiosArgs = {
      url: `/user/me`,
      options: {
        method: 'GET',
        params: {
          start_time,
          end_time,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateSubaccountPassword(customer_id, new_password, confirm_new_password) {
    const localVarAxiosArgs = {
      url: '/user/update_subaccount_password',
      options: {
        method: 'POST',
        data: {
          customer_id,
          new_password,
          confirm_new_password,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateAccount({
    primary_contact,
    primary_contact_email,
    primary_contact_phone,
    time_zone_pdt_offset,
  }) {
    const localVarAxiosArgs = {
      url: '/user/update_account',
      options: {
        method: 'POST',
        data: {
          primary_contact,
          primary_contact_email,
          primary_contact_phone,
          time_zone_pdt_offset,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  signinsso(username) {
    const localVarAxiosArgs = {
      url: `/sso/sso-callback`,
      options: {
        method: 'POST',
        params: {
          username: username,
        },
      },
    };
    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  addressLimitExceeded() {
    const localVarAxiosArgs = {
      url: `/user/address-limit-exceeded`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
